import React, { useState, useEffect } from 'react';
import AdminLayout from './adminComponents';
import { Card, Button, Table, Modal, Form, Input, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './styles/admins.css';
import { createAdmin, updateAdmin, deleteAdmin, getAdmins, getAdmin } from '../Routes';

const Admins = () => {
  const [visible, setVisible] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const fetchAdmins = async () => {
      const fetchedAdmins = await getAdmins();
      setAdmins(fetchedAdmins);
    };

    fetchAdmins();
  }, []);

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'full_name', key: 'full_name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { 
      title: 'Role', 
      dataIndex: 'is_super', 
      key: 'is_super',
      render: is_super => is_super ? 'Super admin' : 'Admin'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => openModal(record)}>Edit</Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>Delete</Button>
        </span>
      ),
    },
  ];

  const openModal = async (admin) => {
    if (admin) {
      const fetchedAdmin = await getAdmin(admin.id);
      setCurrentAdmin(fetchedAdmin);
    } else {
      setCurrentAdmin(null);
    }
    setVisible(true);
  };

  const closeModal = () => {
    setCurrentAdmin(null);
    setVisible(false);
  };

  const handleDelete = async (id) => {
    await deleteAdmin(id);
    const updatedAdmins = await getAdmins();
    setAdmins(updatedAdmins);
  };

  const onFinish = async (values) => {
    if (currentAdmin) {
      await updateAdmin(currentAdmin.id, values);
    } else {
      await createAdmin(values);
    }
    const updatedAdmins = await getAdmins();
    setAdmins(updatedAdmins);
    closeModal();
  };

  return (
    <AdminLayout title="Admins">
      <Button type="primary" className='admin-btn' onClick={() => openModal(null)}>
        <PlusOutlined />Create New Admin
      </Button>
      <div className="card-container">
        <Card title="Number of Admins" className="card">
            {admins && admins.length}
        </Card>
      </div>
      <Table dataSource={admins} columns={columns} rowKey="id" />
      <Modal open={visible} onCancel={closeModal} footer={null}>
        <Form
            name="admin"
            initialValues={currentAdmin}
            onFinish={onFinish}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 14,
            }}
            layout="horizontal"
            style={{
                maxWidth: 600,
            }}
            >
            <Form.Item
                name="full_name" 
                label="Full Name"
                rules={[{ required: true, message: 'Please input the Full Name!' }]}
            >
                <Input placeholder="Full Name" />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please input the Email!' }]}
            >
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input the Password!' }]}
            >
                <Input placeholder="Password" />
            </Form.Item>
            <Form.Item
                name="phone"
                label="Phone Number"
            >
                <Input placeholder="Phone Number" />
            </Form.Item>
            <Form.Item label="Super Admin" name="is_super" valuePropName="checked"> 
                <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </Form>
      </Modal>
    </AdminLayout>
  );
};

export default Admins;