import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { checkSession } from './Routes.js';

// Create a context for the authentication state
const AuthContext = createContext();

// Provider component that wraps your application
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

 // Function to check if the user is authenticated
const checkAuth = async () => {
  try {
    // Call the checkSession function to verify the session with the server
    const data = await checkSession();
    if (data.user) {
      setUser(data.user);
    } else {
      // If the session is invalid, clear the user state
      setUser(null);
      console.log('Session invalid or expired');
    }
  } catch (error) {
    console.error('Error checking authentication:', error);
    // Clear the user state in case of an error
    setUser(null);
  } finally {
    setLoading(false);
  }
};


  useEffect(() => {
    if (location.pathname !== '/') { // Check if the current route is not the login page
      checkAuth();
    } else {
      setLoading(false); // If it's the login page, set loading to false immediately
    }
  }, [location]); // Add location to the dependency array

  // The value that will be provided to the context consumers
  const authValue = {
    user,
    loading,
    setUser, // Add the setUser function
    // Add any other authentication-related values or functions here
  };

  return (
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the authentication context
export const useAuth = () => useContext(AuthContext);