export const Mctb = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', overflow: 'hidden' }}>
      <iframe
        title="Schedule an appointment"
        src="https://api.leadconnectorhq.com/widget/booking/S6NpXxsgW3O63YgP4kgr" 
        style={{width: '50%', border: 'none', height: '750px', overflow: 'hidden', marginTop: '20px'}}
        scrolling="no"
        id="S6NpXxsgW3O63YgP4kgr_1710426170937">
      </iframe>
      <br />
      <script src="https://link.msgsndr.com/js/form_embed.js" type="text/javascript"></script>
    </div>
  );
  
  export default Mctb;