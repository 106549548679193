import axios from 'axios';

const api = axios.create({
    // baseURL: 'http://localhost:5000/api/',
    // baseURL: 'https://bestusawatches.pythonanywhere.com/api/',
    baseURL: 'https://server.bestassistantusa.com/api/',
    // baseURL: 'https://best-virtual-assistance-api.vercel.app/api',
    withCredentials: true
});

export default api;
