import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Typography, Avatar, Badge } from 'antd';
import { logout } from '../Routes';
import {
  DesktopOutlined,
  PieChartOutlined,
  DollarOutlined,
  UserOutlined,
  BellOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  FormOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import './styles/adminComponents.css';

const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography;

export const MyHeader = ({ title }) => {
  const [avatarSize, setAvatarSize] = useState(24);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setAvatarSize(16);
      } else if (window.innerWidth < 768) {
        setAvatarSize(20);
      } else {
        setAvatarSize(24);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Get the admin's full name from local storage
  const fullName = localStorage.getItem('full_name') || '';

  // Get the first letter of the first two words in the full name
  const avatarText = fullName.split(' ').slice(0, 2).map(name => name[0]).join('');

  return (
    <Header className="header">
      <Title level={3} className='title'>{title}</Title> 
      <div className='avatar'>
        <Badge dot>
          <BellOutlined className='bell' />
        </Badge>
        <Avatar className='avatar' size={avatarSize}>{avatarText}</Avatar>
        <Title level={3} className='title'>{fullName}</Title>
      </div>
    </Header>
  );
};

  export const MySider = ({ isSuperAdmin }) => {
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(location.pathname);
    const navigate = useNavigate();

    const handleLogout = async () => {
      try {
        await logout();
    
        // Remove all information from local storage
        localStorage.clear();
    
        // Redirect the user to the login page
        navigate('/');
      } catch (error) {
        console.error('Failed to logout:', error);
      }
    };
    
    useEffect(() => {
      const handleResize = () => {
        setCollapsed(window.innerWidth < 768);
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    useEffect(() => {
      setSelectedKey(location.pathname);
    }, [location.pathname]);
  
    const handleSelect = ({ key }) => {
      setSelectedKey(key);
    };

  
    return (
      <Sider
        className='sider'
        style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }}
        collapsible collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <div className="logo">
          <img src='/best.png' alt="Logo" />
        </div>
        <Menu theme="light" selectedKeys={[selectedKey]} mode="inline" onSelect={handleSelect}>
          <Menu.Item key="/adminDashboard" icon={<PieChartOutlined />}>
            <Link to='/adminDashboard'>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key='/Users' icon={<UserOutlined />}>
            <Link to='/Users'>Users</Link>
          </Menu.Item>
          <Menu.Item key='/Agents' icon={<UserOutlined />}>
            <Link to='/Agents'>Agents</Link>
          </Menu.Item>
          <Menu.Item key="/Payments" icon={<DollarOutlined />}>
            <Link to='/Payments'>Payments</Link>
          </Menu.Item>
          <Menu.Item key="/Services" icon={<DesktopOutlined />}>
            <Link to='/Services'>Services</Link>
          </Menu.Item>
          <Menu.Item key="/Schedules" icon={<ScheduleOutlined />}>
            <Link to='/Schedules'>Schedules</Link>
          </Menu.Item>
          <Menu.Item key="/Requests" icon={<FormOutlined />}>
            <Link to='/Requests'>Requests</Link>
          </Menu.Item>
          {isSuperAdmin && (
            <Menu.Item key="/Admins" icon={<UserSwitchOutlined />}>
              <Link to='/Admins'>Admins</Link>
            </Menu.Item>
          )}
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            style={{ 
              position: 'absolute', 
              marginTop: 100, 
              border: '1px solid red',
            }}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
    );
  };


export const MyFooter = () => (
    <Footer className="footer">Best Virtual Assistant ©2024 Created by Naziff</Footer>
);

const AdminLayout = ({ title, children }) => {
  const isSuperAdmin = localStorage.getItem('is_super') === 'true';
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Layout className="layout">
      <MySider isSuperAdmin={isSuperAdmin} collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout className="site-layout"  style={{ marginLeft: collapsed ? 80 : 200 }} >
        <MyHeader title={title} />
        <Content className="content">{children}</Content>
        <MyFooter />
      </Layout>
    </Layout>
  );
};

export default AdminLayout;