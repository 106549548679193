import React, { useState, useEffect } from 'react';
import AdminLayout from './adminComponents';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Form, Modal, Input, Checkbox, Tag, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getAllAgents, deleteAgent, createAgent, getServices, updateAgent } from '../Routes';
import './styles/agent.css';

const { Option } = Select;

const Agents = () => {
    const [allAgents, setAllAgents] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [allServices, setAllServices] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [currentAgent, setCurrentAgent] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);

// Function to handle service selection change
const handleServiceSelectionChange = (selectedOptions) => {
  // Convert the selected option values (which are service names) to their IDs
  const serviceIds = selectedOptions.map(option => {
    // Find the service object in allServices that matches the selected option
    const service = allServices.find(s => s.name === option);
    return service ? service.id : null; // Return the ID if found, otherwise null
  }).filter(id => id !== null); // Filter out any null values

  // Update the state with the selected service IDs
  setSelectedServices(serviceIds);
};
  
    useEffect(() => {
      getAllAgents()
        .then((data) => setAllAgents(data))
        .catch((error) => console.error('Error fetching all Agents:', error));
    }, []);

    useEffect(() => {
        getServices()
          .then((data) => setAllServices(data))
          .catch((error) => console.error('Error fetching all services:', error));
      }, []);
    

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        form
          .validateFields()
          .then((values) => {
            // Replace the service names with their IDs in the values object
            values.services = selectedServices;
      
            if (currentAgent) {
              // If the password field is empty, remove it from the values object
              if (values.password === '') {
                delete values.password;
              }
      
              // Update the agent
              updateAgent(currentAgent.id, values)
                .then((data) => {
                  // The agent was updated successfully
                  console.log(data);
                  setIsModalVisible(false);
                  setAllAgents(allAgents.map(agent => agent.id === data.id ? data : agent));  // Update the agent in the table
                  setCurrentAgent(null);
                })
                .catch((error) => {
                  // Handle error
                  console.error('Failed to update agent:', error);
                });
            } else {
              // Create a new agent
              console.log("Payload to send to backend:", values);
              createAgent(values)
                .then((data) => {
                  // The agent was created successfully
                  console.log(data);
                  setIsModalVisible(false);
                  setAllAgents([...allAgents, data]);  // Add the new agent to the table
                })
                .catch((error) => {
                  // Handle error
                  console.error('Failed to create agent:', error);
                });
            }
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      };

    const handleCancel = () => {
    setIsModalVisible(false);
    };

    const handleEdit = (record, event) => {
        event.stopPropagation();
        setCurrentAgent(record);
        setIsModalVisible(true);
      };
      
      useEffect(() => {
        if (currentAgent) {
          // Clone the currentAgent object
          const agent = { ...currentAgent };
          // Remove the password field
          delete agent.password;
          // Set the form fields value
          form.setFieldsValue(agent);
        } else {
          form.resetFields();
        }
      }, [currentAgent, form]);

  const columns = [
    {
      title: 'Agent ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
        title: 'Services',
        dataIndex: 'services',
        key: 'services',
        render: services => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {services.map((service, index) => (
                    <Tag color="blue" key={index} style={{ margin: '2px' }}>
                    {service.name || service}
                </Tag>
                ))}
            </div>
        ),
    },    
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
            <Button onClick={(event) => handleEdit(record, event)}>Edit</Button>
            <Button onClick={() => handleDelete(record)}>Delete</Button>
        </span>
      ),
    },
  ];

  const handleRowClick = (record) => {
    navigate(`/agent-details/${record.id}`);
  };

  const handleDelete = (record) => {
    deleteAgent(record.id)
      .then(() => {
        setAllAgents(allAgents.filter(agent => agent.id !== record.id));
      })
      .catch((error) => console.error('Error deleting agent:', error));
  };

  return (
    <AdminLayout title="Agents">
      <div>
      <Button className='create-btn'type="primary"  onClick={showModal}>
      <PlusOutlined />Create Agent
        </Button>
        <Modal
        title={currentAgent ? 'Edit Agent' : 'Create Agent'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={currentAgent ? 'Update Agent' : 'Create Agent'}
        >
          <Form form={form} layout="vertical" initialValues={{ is_agent: true }}>
            <Form.Item name="email" label="Email" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="full_name" label="Full Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="phone_number" label="Phone Number">
              <Input />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: !currentAgent }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item name="services" label="Services">
                <Select
                    mode="multiple"
                    placeholder="Select services"
                    onChange={handleServiceSelectionChange}
                >
                    {allServices.map(service => (
                    <Option key={service.id} value={service.name}>{service.name}</Option>
                    ))}
                </Select>
            </Form.Item>
              <Form.Item name="is_agent" valuePropName="checked">
                <Checkbox>Is Agent</Checkbox>
              </Form.Item>
              
          </Form>
        </Modal>
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
          dataSource={allAgents}
          columns={columns}
          pagination={{ pageSize: 10 }}
        />
      </div>
    </AdminLayout>
  );
};

export default Agents;