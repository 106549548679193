import AdminLayout from './adminComponents';
import './styles/adminDashboard.css';
import { Card, Row, Col, Table } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { countAllUsers, getAssistanceRequests, getTopAgents, countAgents } from '../Routes';
import {
  format,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  eachDayOfInterval,
  eachMonthOfInterval,
  isSameDay,
  isSameMonth } from 'date-fns';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

const { Column } = Table;


const formatData = (requests, timePeriod) => {
  const now = new Date();
  let start;
  let data = [];

  switch (timePeriod) {
    case 'day':
      start = startOfDay(now);
      data = requests.map(request => ({
        name: format(new Date(request.created_at), 'HH:mm'),
        requests: request.count,
      }));
      break;
    case 'week':
      start = startOfWeek(now);
      data = eachDayOfInterval({ start, end: now }).map(date => ({
        name: format(date, 'iii'),
        requests: requests.filter(request => isSameDay(new Date(request.created_at), date)).length,
      }));
      break;
      case 'month':
        start = startOfMonth(now);
        data = eachDayOfInterval({ start, end: now }).map(date => ({
          name: format(date, 'd MMM'),
          requests: requests.filter(request => isSameDay(new Date(request.created_at), date)).length,
        }));
        break;
    case 'year':
      start = startOfYear(now);
      data = eachMonthOfInterval({ start, end: now }).map(date => ({
        name: format(date, 'MMMM'),
        requests: requests.filter(request => isSameMonth(new Date(request.created_at), date)).length,
      }));
      break;
    default:
      start = startOfWeek(now);
  }
  return data;
};

const AdminDashboard = () => {
  const [users, setUsers] = useState(0);
  const [agents, setAgents] = useState(0);
  const [requests, setRequests] = useState([]);
  const [topAgentsData, setTopAgentsData] = useState([]);
  const [timePeriod, setTimePeriod] = useState('week');
  const formattedData = formatData(requests, timePeriod);
  

  const navigate = useNavigate();

  useEffect(() => {
    countAllUsers()
      .then((data) => { setUsers(data); })
      .catch((error) => console.error('Error fetching users:', error));
  
    countAgents()
      .then((data) => { setAgents(data); })
      .catch((error) => console.error('Error fetching agents:', error));
  
    getAssistanceRequests()
      .then((data) => { setRequests(data); })
      .catch((error) => console.error('Error fetching requests:', error));
  
    getTopAgents()
      .then((data) => {
        const formattedData = data.map(item => ({
          id: item.id,
          name: item.full_name,
          requests: item.total_requests,
          completedServices: item.completed_requests,
          ongoingServices: item.ongoing_requests,
        }));
        setTopAgentsData(formattedData);
      })
      .catch((error) => console.error('Error fetching top agents:', error));
  }, []);


  const newRequests = requests.filter(request => {
    const createdAt = new Date(request.created_at);
    const oneDayAgo = new Date(Date.now() - 48 * 60 * 60 * 1000);
    return createdAt >= oneDayAgo;
  });

  const transformedNewRequests = newRequests.map(request => ({
    ...request,
    agent: request.agent.map(a => a.full_name).join(', '),
  }));
  

  const completedRequests = requests.filter(request => request.status === 'completed');

  const columns = [
    { 
      title: 'ID',
      dataIndex: 'id',
      key: 'id' 
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user' 
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
      key: 'agent_name'
    },
    { 
      title: 'Service',
      dataIndex: 'service',
      key: 'service' 
    },
    { 
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date' 
    },
    { 
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date'
    },
    { 
      title: 'Status',
      dataIndex: 'status',
      key: 'status' 
    },
    
  ];
  return (
    <AdminLayout title="Dashboard">
         <div className="content">
        <div className='dash-card'>
          {/* Cards Section */}
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Card title={<span className="title">Users</span>} bordered={false}>
                <span className="value">{users.count}</span>
              </Card>
            </Col>
            <Col span={6}>
              <Card title={<span className="title">Agents</span>} bordered={false}>
                <span className="value">{agents.agents}</span>
              </Card>
            </Col>
            <Col span={6}>
              <Card title={<span className="title">Total Requests</span>} bordered={false}>
                <span className="value">{requests.length}</span>
              </Card>
            </Col>
            <Col span={6}>
              <Card title={<span className="title">Completed Requests</span>} bordered={false}>
                <span className="value">{completedRequests.length}</span>
              </Card>
            </Col>
          </Row>
        </div>
        <div className='analytics'>
          {/* Analytics Section */}
          <Card title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <><BarChartOutlined /> Analytics</>
              <select value={timePeriod} onChange={(e) => setTimePeriod(e.target.value)}>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
            </div>
          } bordered={false}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={formattedData}>
                <XAxis dataKey="name" />
                <YAxis>
                  <Label angle={-90} value='Number of Requests' position='insideLeft' style={{textAnchor: 'middle'}} />
                </YAxis >
                <Tooltip />
                <Legend />
                <Bar dataKey="requests" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </div>
            
        {/* New Requests Section */}
        <Card title="New Requests" bordered={false} className="dashboard-card">
          {/* Add content specific to New Requests */}
          <Table columns={columns} dataSource={transformedNewRequests} />
        </Card>

        {/* Top Agents Section */}
        <Card title="Top Agents" bordered={false}>
        <Table 
            dataSource={topAgentsData} 
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                navigate(`/agent-details/${record.id}`);
              },
            })}
          >
            <Column title="Agent Name" dataIndex="name" key="name" />
            <Column title="Number of Requests" dataIndex="requests" key="requests" />
            <Column title="Completed Requests" dataIndex="completedServices" key="completedServices" />
            <Column title="Ongoing Requests" dataIndex="ongoingServices" key="ongoingServices" />
          </Table>
        </Card>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;