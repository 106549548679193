import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './adminComponents';
import { Table, Select, Button, Modal, Form, Typography, Input, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { deleteSchedule, getAllAgents, editSchedule, getAllUsers, scheduleAppointment, getAllSchedules, getAssistanceRequests } from '../Routes';
// import dayjs from 'dayjs';
// import moment from 'moment';
import './styles/schedule.css'

const { Option } = Select;

const Schedules = () => {
  const [currentView, setCurrentView] = useState('all');
  const [selectedButton, setSelectedButton] = useState('all');
  const [agents, setAgents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [users, setUsers] = useState([]);
  const [requests, setRequests] = useState([]);
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [tick, setTick] = useState(0);
  const navigate = useNavigate();


  // const formatDate = (dateString) => {
  //   // Directly pass the date string to moment
  //   return moment(dateString, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').format('YYYY-MM-DD');
  // };
  // const formatTime = (timeString) => {
  //   // Assuming timeString is in the format 'HH:MM:SS'
  //   return timeString.substring(0, 5); // This will return 'HH:MM'
  // };


  const fetchAllSchedules = async () => {
    try {
      const data = await getAllSchedules();
      setData(data);
    } catch (error) {
      console.error('Failed to fetch schedules:', error);
    }
  };

  
  useEffect(() => {
    const timer = setInterval(() => {
      setTick(tick => tick + 1); // This will cause a re-render
    }, 60000); // Update every minute

    return () => clearInterval(timer); // Clean up on unmount
  }, []);

  useEffect(() => {
    const fetchUsersAndRequests = async () => {
      try {
        const fetchedUsers = await getAllUsers();
        const fetchedRequests = await getAssistanceRequests();
        setUsers(fetchedUsers);
        setRequests(fetchedRequests);
      } catch (error) {
        console.error('Failed to fetch users or services:', error);
      }
    };

    fetchUsersAndRequests();
  }, []);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const fetchedAgents = await getAllAgents();
        setAgents(fetchedAgents);
      } catch (error) {
        console.error('Failed to fetch agents:', error);
      }
    };

    fetchAgents();
  }, []);
  
  useEffect(() => {
    fetchAllSchedules();
  }, []);

  const handleEdit = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    // If start_time is not set, set it to the current time
    // if (!currentRecord.start_time) {
    //   setCurrentRecord(prevState => ({
    //     ...prevState,
    //     start_time: dayjs().startOf('hour').format('HH:mm')
    //   }));
    // }

    if (currentRecord.id) {
    //   console.log('date:', currentRecord.date, 'start_time:', currentRecord.start_time)
    //   // Format the date and time
    //   const formattedDate = (currentRecord.date);
    //   const formattedTime = formatTime(currentRecord.start_time);
    //   console.log('formattedDate:', formattedDate, 'formattedTime:', formattedTime);

      // Prepare the updated record
      const updatedRecord = { 
        ...currentRecord, 
        // date: formattedDate, 
        // start_time: formattedTime,
        // Add the agents array
        agents: (currentRecord.agentId || []).map((id, index) => ({
          id: id,
          name: (currentRecord.agentName || [])[index] || ''
        }))
      };

      // Update the schedule in the database
      try {
        const updatedSchedule = await editSchedule(currentRecord.id, updatedRecord);
        // Update the schedule in the local state
        console.log('updatedSchedule:', updatedSchedule);
        setSchedules(prevSchedules =>
          prevSchedules.map(schedule =>
            schedule.id === currentRecord.id ? updatedSchedule : schedule
          )
        );

        // Fetch all schedules after updating
        fetchAllSchedules();
      } catch (error) {
        console.error('Failed to update schedule:', error);
      }
    } else {
      // Create a new schedule in the database
      try {
        const newSchedule = await scheduleAppointment(currentRecord);
        // Add the new schedule to the local state
        setSchedules([...schedules, newSchedule]);

        // Fetch all schedules after creating
        fetchAllSchedules();
      } catch (error) {
        console.error('Failed to create schedule:', error);
      }
    }

    // setIsModalVisible(false);
    navigate('/mctb');
 };
 
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deleteScheduleAndUpdateState = async (id) => {
    try {
      await deleteSchedule(id);
      setSchedules(schedules.filter(schedule => schedule.id !== id));
      fetchAllSchedules();
    } catch (error) {
      console.error('Failed to delete schedule:', error);
    }
  };

  const columns = [
    {
      title: 'Assistance ID',
      dataIndex: 'assistance_id',
      key: 'assistance_id',
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Agent Name',
      dataIndex: 'agents',
      key: 'agents',
      render: (agents) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Array.isArray(agents) ? agents.map((agentName, index) => (
            <Tag color="blue" key={index} style={{ margin: '2px' }}>
              {agentName}
            </Tag>
          )) : null}
        </div>
      ),
    },
    // {
    //   title: 'Date',
    //   dataIndex: 'date',
    //   key: 'date',
    //   render: (date) => formatDate(date),
    // },
    // {
    //   title: 'Time',
    //   dataIndex: 'start_time',
    //   key: 'start_time',
    //   render: (time) => formatTime(time),
    // },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   render: (_text, record) => {
    //     const now = new Date();
    //     let status;
    
    //     // Create date and time separately
    //     const startDate = new Date(record.date);
    //     const [startHours, startMinutes] = record.start_time.split(':');
    //     startDate.setHours(startHours, startMinutes);
    
    //     const endDate = new Date(record.date);
    //     const [endHours, endMinutes] = record.end_time.split(':');
    //     endDate.setHours(endHours, endMinutes);
  
    //     if (now < startDate) {
    //       status = 'pending';
    //     } else if (now > endDate) {
    //       status = 'completed';
    //     } else {
    //       status = 'ongoing';
    //     }
    
    //     return <span style={{ textTransform: 'capitalize', color: getStatusColor(status) }}>{status}</span>;
    //   },
    // },
    {
      title: 'Status',
      key: 'status',
      render: (_text, record) => {
        const status = record.status;
    
        return (
          <span style={{ textTransform: 'capitalize', color: getStatusColor(status) }}>
            {status}
          </span>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_text, record) => (
        <>
          <Button className= 'schedule-button' type="primary" onClick={() => handleEdit(record)}>
            <EditOutlined />
          </Button>
          <Button className='delete-button' type="danger" onClick={() => {
            Modal.confirm({
              title: 'Are you sure you want to delete this schedule?',
              onOk() {
                deleteScheduleAndUpdateState(record.id);
              },
            });
          }}>
            <DeleteOutlined />
          </Button>
        </>
      ),
    },
  ];
  
  let filteredData = data;
  const currentTime = new Date();
  switch (currentView) {
    case 'new':
      filteredData = data.filter(item => {
        const itemTime = new Date(item.created_at);
        const diffTime = Math.abs(currentTime - itemTime);
        const diffHours = diffTime / (1000 * 60 * 60);
        return diffHours < 24 && item.status === 'pending';
      });
      break;
    case 'pending':
      filteredData = data.filter(item => item.status === 'pending');
      break;
    case 'completed':
      filteredData = data.filter(item => item.status === 'completed');
      break;
    default:
      break;
  }

  function getStatusColor(status) {
    switch (status) {
      case 'ongoing':
        return 'green';
      case 'completed':
        return '#02035E';
      case 'pending':
        return 'orange';
      default:
        return 'black';
    }
  }

  const handleButtonClick = (view) => {
    // Remove the 'selected' class from all buttons
    const buttons = document.querySelectorAll('.schedules-for button');
    buttons.forEach(button => button.classList.remove('selected'));

    // Add the 'selected' class to the clicked button
    const button = document.querySelector(`.schedules-for button[data-view="${view}"]`);
    if (button) {
        button.classList.add('selected');
    }

    // Set the current view and selected button
    setSelectedButton(view);
    setCurrentView(view);
};
  
  return (
    <AdminLayout title="Schedules">
      <Button className='create-button' type="primary" onClick={() => {
         setCurrentRecord({
          agentName: [], // Clear agentName
        });
          setIsModalVisible(true);
        }}> <PlusOutlined />Create Schedule
        </Button>
      <div className='schedules-for'>
        <button className={selectedButton === 'all' ? 'selected' : ''} onClick={() => handleButtonClick('all')}>
          <span className="full-text">All Schedules</span>
          <span className="short-text">All</span>
        </button>
        <button className={selectedButton === 'new' ? 'selected' : ''} onClick={() => handleButtonClick('new')}>
          <span className="full-text">New Schedules</span>
          <span className="short-text">New</span>
        </button>
        <button className={selectedButton === 'pending' ? 'selected' : ''} onClick={() => handleButtonClick('pending')}>
          <span className="full-text">Pending Schedules</span>
          <span className="short-text">Pending</span>
        </button>
        <button className={selectedButton === 'completed' ? 'selected' : ''} onClick={() => handleButtonClick('completed')}>
          <span className="full-text">Completed Schedules</span>
          <span className="short-text">Completed</span>
        </button>
      </div>
      <Table columns={columns} dataSource={filteredData} scroll={{ x: 'max-content' }} />
      <Modal
        title={currentRecord.id ? "Edit Schedule" : "Create Schedule"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
          <Form
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleOk}>
          <Form.Item
            label="Topic"
            rules={[{ required: true, message: 'Please input the topic!' }]} 
          >
            <Input 
              value={currentRecord?.topic || ''} 
              onChange={(e) => {
                setCurrentRecord({ ...currentRecord, topic: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Request Description"
            rules={[{ required: true, message: 'Please select a request!' }]}
            >
            {!!currentRecord.id ? (
                <Typography.Text>{requests.find(request => request.id === currentRecord.assistance_id)?.description || 'N/A'}</Typography.Text>
            ) : (
                <Select 
                  showSearch
                  value={currentRecord?.assistance_id || 'Select Request'} 
                  onSelect={(value) => {
                    const request = requests.find(request => request.id === value);
                    const user = users.find(user => user.id === request.user_id);
                    // Flatten the agent array if necessary and map to the expected format
                    const agentData = request.agent ? request.agent.flat().map(agent => ({ key: agent.id, label: agent.full_name })) : [];
                     setCurrentRecord({ 
                      ...currentRecord, 
                      assistance_id: value, 
                      user_id: user.id, 
                      user: user.full_name, 
                      agentData: agentData, // Ensure agentData is set correctly
                      agentName: agentData.map(agent => agent.label), // Set the agent names
                      agentId: agentData.map(agent => agent.key) // Set the agent IDs
                    });
                  }}
                >
                  {requests.map(assistance => <Option key={assistance.id} value={assistance.id}>{assistance.description}</Option>)}
                </Select>
            )}
          </Form.Item>
          <Form.Item
            label="User Name"
            rules={[{ required: true, message: 'Please select a user!' }]}
            >
            {!!currentRecord.id ? (
                <Typography.Text>{currentRecord.user}</Typography.Text>
            ) : (
                <Select 
                  showSearch
                  value={users.find(user => user.full_name === currentRecord.user)?.id || 'Select User'} 
                  onSelect={(value) => {
                    const user = users.find(user => user.id === value);
                    setCurrentRecord({ ...currentRecord, user_id: value, user: user?.full_name });
                  }}
                >
                  {users.map(user => <Option key={user.id} value={user.id}>{user.full_name}</Option>)}
                </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Agent Name"
            rules={[{ required: true, message: 'Please select at least an agent!' }]}
            >
            <Select
                mode="multiple"
                showSearch
                labelInValue
                placeholder='Select Agents'
                value={currentRecord?.agentName?.map(name => ({ key: agents.find(agent => agent.full_name === name)?.id, label: name })) || []} 
                onChange={(value) => {
                  const agentNames = value.map(val => agents.find(agent => agent.id === val.key)?.full_name);
                  setCurrentRecord({ ...currentRecord, agentId: value.map(val => val.key), agentName: agentNames });
                }}
            >
                {agents.map(agent => <Option key={agent.id} value={agent.id}>{agent.full_name}</Option>)}
            </Select>
         </Form.Item>
         <Form.Item
            label="Status"
            rules={[{ required: true, message: 'Please select a status!' }]}
          >
            <Select 
              value={currentRecord?.status || 'pending'} 
              onSelect={(value) => {
                setCurrentRecord({ ...currentRecord, status: value });
              }}
            >
              <Option value="pending" style={{ color: getStatusColor('pending') }}>Pending</Option>
              <Option value="ongoing" style={{ color: getStatusColor('ongoing') }}>Ongoing</Option>
              <Option value="completed" style={{ color: getStatusColor('completed') }}>Completed</Option>
            </Select>
          </Form.Item>
           {/* <Form.Item
            label="Date"
            rules={[{ required: true, message: 'Please select a date!' }]}
            >
              <DatePicker 
                value={currentRecord?.date ? dayjs(currentRecord.date) : null} 
                onChange={(date, dateString) => {
                  console.log("Selected Date:", dateString);
                  setCurrentRecord({ ...currentRecord, date: (dateString) });
                }}
              />
          </Form.Item>
          <Form.Item
            label="Time"
            rules={[{ required: true, message: 'Please select a time!' }]}
            >
            <DatePicker 
              format='HH:mm' 
              picker='time' 
              showSecond={false} 
              minuteStep={30} 
              value={currentRecord?.start_time ? dayjs(`1970-01-01T${currentRecord.start_time}:00`) : dayjs().startOf('hour')} 
              onChange={(time, timeString) => {
                setCurrentRecord({ ...currentRecord, start_time: timeString });
              }} 
            />
          </Form.Item> */}
          </Form>
        </Modal>
    </AdminLayout>
  );

};
export default Schedules;