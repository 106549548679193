import api from './Api';

// Function to check if the user is authenticated
export const checkSession = async () => {
  try {
    const response = await api.get('/check_session', {
      withCredentials: true // Include cookies in the request
    });
    return response.data;
  } catch (error) {
    console.error('Error checking authentication:', error);
    throw error;
  }
};



/* users routes */
export const getAllUsers = async () => {
    try {
      const response = await api.get('/users');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch all users:', error);
      throw error;
    }
  };
  
  export const getUserById = async (userId) => {
    try {
      const response = await api.get(`/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch user with ID ${userId}:`, error);
      throw error;
    }
  };
  
  export const deleteUser = async (userId) => {
    try {
      const response = await api.delete(`/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to delete user with ID ${userId}:`, error);
      throw error;
    }
  };
  
  export const updateUser = async (userId, userData) => {
    try {
      const response = await api.put(`/users/${userId}`, userData);
      return response.data;
    } catch (error) {
      console.error(`Failed to update user with ID ${userId}:`, error);
      throw error;
    }
  };
  
  export const getUsersByAdmin = async (adminId) => {
    try {
      const response = await api.get(`/users/${adminId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch users for admin with ID ${adminId}:`, error);
      throw error;
    }
  };
  
  export const countAllUsers = async () => {
    try {
      const response = await api.get('/users/count');
      return response.data;
    } catch (error) {
      console.error('Failed to count all users:', error);
      throw error;
    }
  };
  
  export const countUsersByAdmin = async (adminId) => {
    try {
      const response = await api.get(`/users/${adminId}/count`);
      return response.data;
    } catch (error) {
      console.error(`Failed to count users for admin with ID ${adminId}:`, error);
      throw error;
    }
  };
  
  export const getTopUsers = async () => {
    try {
      const response = await api.get('/users/top');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch top users:', error);
      throw error;
    }
  };
  
  export const getTopUsersByAdmin = async (adminId) => {
    try {
      const response = await api.get(`/users/top/${adminId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch top users for admin with ID ${adminId}:`, error);
      throw error;
    }
  };


/* services routes */
// Function to get services based on admin_id
export const getServices = async() => {
    try {
        const response = await api.get(`/services`);
        return response.data;
    } catch (error) {
        console.error('Error fetching services', error);
        throw error
    }
};

export const getServiceById = async (serviceId) => {
    try {
      const response = await api.get(`/services/${serviceId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch service with ID ${serviceId}:`, error);
      throw error;
    }
  };

export const getServicesByAdmin = async (adminId) => {
    try {
      const response = await api.get(`/services?admin_id=${adminId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching services:', error);
      throw error;
    }
  };
  
  // Function to create a new service
  export const createService = async (data) => {
    try {
      const response = await api.post('/services', data);
      return response.data;
    } catch (error) {
      console.error('Error creating service:', error);
      throw error;
    }
  };
  
  // Function to update an existing service
  export const updateService = async (serviceId, data) => {
    try {
      const response = await api.put(`/services/${serviceId}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating service:', error);
      throw error;
    }
  };

  // Function to delete a task
  export const deleteService = async (serviceId) => {
    try {
      await api.delete(`/services/${serviceId}`);
    } catch (error) {
      console.error('Error deleting service:', error);
      throw error;
    }
  };

  /* task routes */
  // Function to get all tasks
export const getTasks = async () => {
    try {
      const response = await api.get('/tasks');
      return response.data;
    } catch (error) {
      console.error('Error fetching tasks:', error);
      throw error;
    }
  };
  
  // Function to create a new task
  export const createTask = async (data) => {
    try {
      const response = await api.post('/tasks', data);
      return response.data;
    } catch (error) {
      console.error('Error creating task:', error);
      throw error;
    }
  };
  
  // Function to update an existing task
  export const updateTask = async (taskId, data) => {
    try {
      const response = await api.put(`/tasks/${taskId}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  };
  
  // Function to delete a task
  export const deleteTask = async (taskId) => {
    try {
      await api.delete(`/tasks/${taskId}`);
    } catch (error) {
      console.error('Error deleting task:', error);
      throw error;
    }
  };

  export const getTasksByService = async (serviceId) => {
    try {
      const response = await api.get(`/tasks/task/${serviceId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching tasks:', error);
      throw error;
    }
  };


/* schedule routes */
export const scheduleAppointment = async (data) => {
    try {
      const response = await api.post('/schedule', data);
      return response.data;
    } catch (error) {
      console.error('Error scheduling appointment:', error);
      throw error;
    }
  };
  
  export const getAllSchedules = async () => {
    try {
      const response = await api.get('/schedule');
      return response.data;
    } catch (error) {
      console.error('Error fetching all schedules:', error);
      throw error;
    }
  };
  
  export const getAdminSchedules = async (adminId) => {
    try {
      const response = await api.get(`/schedule/${adminId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching admin schedules:', error);
      throw error;
    }
  };
  
  export const editSchedule = async (scheduleId, data) => {
    try {
      const response = await api.put(`/schedule/${scheduleId}`, data);
      return response.data;
    } catch (error) {
      console.error('Error editing schedule:', error);
      throw error;
    }
  };
  
  export const deleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedule/${scheduleId}`);
    } catch (error) {
      console.error('Error deleting schedule:', error);
      throw error;
    }
  };

  export const getRoleScheduleRequests = async (role, roleId) => {
    try {
      const response = await api.get(`/schedule/${role}/${roleId}`);
      return response.data;
    } catch (error) {
      console.error('Error loading role schedule requests:', error);
      throw error;
    }
  };


// agents routes
  export const getAllAgents = async () => {
    try {
      const response = await api.get('/agents');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch all agents:', error);
      throw error;
    }
  };

  export const getAgentById = async (agentId) => {
    try {
      const response = await api.get(`/agents/${agentId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch agent with ID ${agentId}:`, error);
      throw error;
    }
  };

  export const deleteAgent = async (agentId) => {
    try {
      const response = await api.delete(`/agents/${agentId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to delete agent with ID ${agentId}:`, error);
      throw error;
    }
  };

  export const updateAgent = async (agentId, agentData) => {
    try {
      const response = await api.put(`/agents/${agentId}`, agentData);
      return response.data;
    } catch (error) {
      console.error(`Failed to update agent with ID ${agentId}:`, error);
      throw error;
    }
  };

  // function to create agent
  export const createAgent = async (agentData) => {
    try {
      const response = await api.post('/agent', agentData);
      return response.data;
    } catch (error) {
      console.error('Failed to create agent:', error);
      throw error;
    }
  }

  // get agents requests
export const getAgentRequests = async (agentId) => {
  try {
    const response = await api.get(`/agents/${agentId}/requests`);
    return response.data;
  } catch (error) {
    console.error('Error loading requests:', error);
    throw error;
  }
};

export const getTopAgents = async () => {
  try {
    const response = await api.get('/agents/top');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch top agents:', error);
    throw error;
  }
};

export const countAgents = async () => {
  try {
    const response = await api.get('/agents/counts');
    return response.data;
  } catch (error) {
    console.error('Failed to count agents:', error);
    throw error;
  }
};

// admin routes
// Function to create a new admin
export const createAdmin = async (data) => {
  try {
    const response = await api.post('/admin', data);
    return response.data;
  } catch (error) {
    console.error('Error creating admin:', error);
    throw error;
  }
};

// Function for admin login
export const adminLogin = async (data) => {
  try {
    const response = await api.post('/admin_login', data);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// update an admin with the admin_id and data
export const updateAdmin = async (adminId, data) => {
  try {
    const response = await api.put(`/admin/${adminId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating admin:', error);
    throw error;
  }
};

// Function to delete an admin
export const deleteAdmin = async (adminId) => {
  try {
    await api.delete(`/admin/${adminId}`);
  } catch (error) {
    console.error('Error deleting admin:', error);
    throw error;
  }
};

// get all admins
export const getAdmins = async () => {
  try {
    const response = await api.get('/admins');
    return response.data;
  } catch (error) {
    console.error('Error loading admins:', error);
    throw error;
  }
}

// get an admin with the admin_id
export const getAdmin = async (adminId) => {
  try {
    const response = await api.get(`/admin/${adminId}`);
    return response.data;
  } catch (error) {
    console.error('Error loading admin:', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await api.post('/admin_logout');
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  };
};


// requests routes
export const createAssistanceRequest = async (data) => {
  try {
    const response = await api.post('/assistance_requests', data);
    return response.data;
  } catch (error) {
    console.error('Error creating assistance request:', error);
    throw error;
  }
};

export const getAssistanceRequest = async (assistanceId) => {
  try {
    const response = await api.get(`/assistance_requests/${assistanceId}`);
    return response.data;
  } catch (error) {
    console.error('Error loading assistance request:', error);
    throw error;
  }
};

export const updateAssistanceRequest = async (assistanceId, data) => {
  try {
    const response = await api.put(`/assistance_requests/${assistanceId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating assistance request:', error);
    throw error;
  }
};

export const deleteAssistanceRequest = async (assistanceId) => {
  try {
    const response = await api.delete(`/assistance_requests/${assistanceId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting assistance request:', error);
    throw error;
  }
};

export const getAssistanceRequests = async () => {
  try {
    const response = await api.get('/assistance_requests');
    return response.data;
  } catch (error) {
    console.error('Error loading assistance requests:', error);
    throw error;
  }
};

export const getRoleAssistanceRequests = async (role, roleId) => {
  try {
    const response = await api.get(`/assistance_requests/${role}/${roleId}`);
    return response.data;
  } catch (error) {
    console.error('Error loading role assistance requests:', error);
    throw error;
  }
};


// payments routes
export const createPayment = async (data) => {
  try {
    const response = await api.post('/payments', data);
    return response.data;
  } catch (error) {
    console.error('Error creating payment:', error);
    throw error;
  }
};


export const getPayments = async () => {
  try {
    const response = await api.get('/payments');
    return response.data;
  } catch (error) {
    console.error('Error loading payments:', error);
    throw error;
  }
}


export const forgotPassword = async (data) => {
  try {
    const response = await api.post('/forgot_password', data);
    return response.data;
  } catch (error) {
    console.error('Error no email found');
    throw error;
  }
}

export const resetPassword = async (data) => {
  try {
    const response = await api.post('/reset_password', data);
    return response.data;
  } catch (error) {
    console.error('Invalid/expired OTP')
  }
}