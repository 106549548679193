import React, { useEffect, useState } from 'react';
import { Card, Button, Modal, Form, Input, List, Popconfirm, Spin, Space } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import AdminLayout from './adminComponents';
import {
  getServices,
  deleteService,
  createService,
  updateService,
  getTasks,
  // createTask,
  deleteTask,
} from '../Routes';
import './styles/service.css';

const Services = () => {
  const [services, setServices] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editingService, setEditingService] = useState(null);
  const [newTaskName, setNewTaskName] = useState('');
  const [newTaskPrice, setNewTaskPrice] = useState('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchServices = async () => {
    try {
      const response = await getServices();
      const data = Array.isArray(response) ? response : [];
      setServices(data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };
  
  const fetchTasks = async () => {
    try {
      const response = await getTasks();
      const data = Array.isArray(response) ? response : [];
      setTasks(data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleOpenModal = (service = null) => {
    setIsModalVisible(true);
    setIsEdit(service ? true : false);
    setEditingService(service);
    setNewTaskName('');
    setNewTaskPrice('');
    form.resetFields();
  
    if (service) {
      form.setFieldsValue({
        name: service.name,
        description: service.description,
      });
      setUpdatedServiceTasks(tasks.filter(task => task.service_id === service.id));
    } else {
      setNewServiceTasks([]);
      setUpdatedServiceTasks([]);
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setIsEdit(false);
    setEditingService(null);
    setNewTaskName('');
    setNewTaskPrice('');
    form.resetFields();
    setUpdatedServiceTasks([]);
  };

  const handleDeleteService = async (serviceId) => {
    try {
      await deleteService(serviceId);
      setServices(services.filter((service) => service.id !== serviceId));
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  const handleCreateService = async () => {
    const newService = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      tasks: newServiceTasks,
    };
  
    setLoading(true);
    try {
      const response = await createService(newService);
      if (response.data && response.data.name) {
        setServices(prevServices => [...prevServices, response.data]);
        fetchTasks(); // Fetch tasks after creating a service
      } else {
        throw new Error('Invalid response from createService');
      }
    } catch (error) {
      console.error('Error creating service:', error);
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };

  const handleUpdateService = async () => {
    const updatedService = {
      id: editingService.id,
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      tasks: updatedServiceTasks,
    };
    console.log('Updated service tasks:', updatedService.tasks); // Debug statement

    try {
      const response = await updateService(updatedService.id, updatedService);
      setServices(
        services.map((service) => (service.id === response.data.id ? response.data : service))
      );
      fetchTasks(); // Fetch tasks after updating a service
      handleCloseModal();
    } catch (error) {
      console.error('Error updating service:', error);
    }
  };

  const [newServiceTasks, setNewServiceTasks] = useState([]);
  const [updatedServiceTasks, setUpdatedServiceTasks] = useState([]);

  const handleAddTask = () => {
    if (newTaskName && newTaskPrice) {
      const newTaskObject = { name: newTaskName, service_id: editingService?.id, amount: newTaskPrice };
      if (isEdit) {
        setUpdatedServiceTasks([...updatedServiceTasks, newTaskObject]);
      } else {
        setNewServiceTasks([...newServiceTasks, newTaskObject]);
      }
      setNewTaskName('');
      setNewTaskPrice('');
    }
  };

  // const handleUpdateTask = (index, data) => {
  //   setUpdatedServiceTasks([
  //     ...updatedServiceTasks.slice(0, index),
  //     { ...data },
  //     ...updatedServiceTasks.slice(index +  1),
  //   ]);
  // };
  const handleUpdateTask = (index, newName, newPrice) => {
    const updatedTasks = [...updatedServiceTasks];
    updatedTasks[index] = { ...updatedTasks[index], name: newName, amount: newPrice };
    setUpdatedServiceTasks(updatedTasks);
  };

  const handleDeleteTask = async (index) => {
    const taskToDelete = updatedServiceTasks[index];
    try {
      await deleteTask(taskToDelete.id); // Call the API endpoint to delete the task
      const updatedTasks = updatedServiceTasks.filter((_, idx) => idx !== index);
      setUpdatedServiceTasks(updatedTasks);
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleDeleteNewTask = (index) => {
    setNewServiceTasks(newServiceTasks.filter((_, idx) => idx !== index));
  };

  useEffect(() => {
    fetchServices();
    fetchTasks();
  }, []);

  if (loading) {
    return <Spin />; 
  }

  return (
    <AdminLayout title="Services">
      <Button className='service-button' onClick={() => handleOpenModal()}>
        <PlusOutlined />Add Service
      </Button>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card
          title={<span style={{ display: 'flex', justifyContent: 'center' }}>Services</span>}
          style={{ width:  300, margin: '20px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <h1>{services && services.length}</h1>
          </div>
        </Card>
        <div className='service-container-card'>
          {services.map((service) => (
            <Card key={service.id} title={service.name} style={{ width: 500, margin: '20px' }}>
              <p>
                <strong>Description:</strong> {service.description}
              </p>
                <strong>Tasks:</strong>
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.filter(task => task.service_id === service.id).map((task) => (
                      <tr key={task.id}>
                        <td>{task.name}</td>
                        <td>{`$${task.amount}`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              <Button
                className='service-button'
                type="primary"
                style={{ marginRight: '10px' }}
                onClick={() => handleOpenModal(service)}
              >
                Edit
              </Button>
              <Popconfirm
                title="Are you sure you want to delete this service?"
                onConfirm={() => handleDeleteService(service.id)}
              >
                <Button className="m-del" type="danger">Delete</Button>
              </Popconfirm>
            </Card>
          ))}
        </div>
      </div>
        <Modal
           key={editingService ? editingService.id : 'new'}
          open={isModalVisible}
          title={isEdit ? 'Edit Service' : 'Create Service'}
          onCancel={handleCloseModal}
          footer={[
            isEdit ? (
              <Button className='service-button' key="update" type="primary" onClick={handleUpdateService}>
                Update
              </Button>
            ) : (
              <Button className='service-button' key="create" type="primary" onClick={handleCreateService}>
                Create
              </Button>
            ),
            <Button className='cancel-btn' key="cancel" onClick={handleCloseModal}>
              Cancel
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter a name' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please enter a description' }]}>
              <Input.TextArea />
            </Form.Item>
            <h4>New Tasks</h4>
            <Space.Compact style={{ marginBottom:   8 }}>
              <Input
                placeholder="Task name"
                value={newTaskName}
                onChange={(e) => setNewTaskName(e.target.value)}
              />
              <Input
                placeholder="Task price"
                value={newTaskPrice}
                onChange={(e) => setNewTaskPrice(e.target.value)}
                prefix="$"
              />
              <Button className='add-btn' onClick={handleAddTask}>Add task</Button>
            </Space.Compact>
            <List
                itemLayout="horizontal"
                dataSource={newServiceTasks}
                renderItem={(task, index) => (
                  <List.Item
                    key={index}
                    actions={[
                      <CloseOutlined className='del-task' onClick={() => handleDeleteNewTask(index)} />
                    ]}
                  >
                    <List.Item.Meta title={task.name} />
                  </List.Item>
                )}
              />
            <List
              itemLayout="horizontal"
              dataSource={updatedServiceTasks}
              renderItem={(task, index) => (
                <List.Item key={index} actions={[<CloseOutlined onClick={() => handleDeleteTask(index)} />]}>
                  <List.Item.Meta
                    title={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Input
                          defaultValue={task.name}
                          onBlur={(e) => handleUpdateTask(index, e.target.value, task.price)}
                          style={{ marginRight: '10px' }}
                        />
                        <Input
                          defaultValue={task.amount}
                          onBlur={(e) => handleUpdateTask(index, task.name, e.target.value)}
                          prefix="$"
                        />
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Form>
        </Modal>

    </AdminLayout>
  );
};

export default Services;

