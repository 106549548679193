import React, { useState } from 'react';
import { Form, Input, Button, Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import './styles/login.css';
import { adminLogin, forgotPassword, resetPassword } from '../Routes';
import { useAuth } from '../AuthContext';

const AdminLogin = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth(); // Get the setUser function from the AuthContext
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [email, setEmail] = useState('');

  const onFinish = async (values) => {
    try {
      const data = await adminLogin(values);
      const { admin, is_super, session_id } = data; // Assuming the server returns a sessionId

      setUser(admin);

      // Set the session ID in the cookies
      // document.cookie = `session_id=${session_id}; path=/; max-age=${60 *  60 *  24 *  7}`;
      // document.cookie = `id=${admin.id}; path=/;`;
      document.cookie = `session_id=${session_id}; path=/; max-age=${60 *  60 *  24 *  7}; Secure; domain=bestassistantusa.com`;
      document.cookie = `id=${admin.id}; path=/; Secure; domain=bestassistantusa.com`;
      localStorage.setItem('full_name', admin.full_name);
      localStorage.setItem('is_super', is_super);
      localStorage.setItem('admin_id', admin.id);

      navigate('/adminDashboard');
    } catch (error) {
      console.error('Failed to login:', error);
      notification.error({
        message: 'Failed to login',
        description: 'Please check your email and password and try again.',
      });
    }
  };

  const onForgotPassword = async (values) => {
    try {
      await forgotPassword(values);
      setEmail(values.email);
      setEmailModalVisible(false);
      setOtpModalVisible(true);
    } catch (error) {
      console.error('Error no email found');
      notification.error({
        message: 'Failed to send OTP',
        description: 'Please check your email and try again.',
      });
    }
  };

  const onResetPassword = async (values, form) => {
    try {
      await form.validateFields(['new_password', 'confirm_password']);
      await resetPassword({ ...values, email });
      setOtpModalVisible(false);
      notification.success({
        message: 'Password has been reset',
        description: 'You can now log in with your new password.',
      });
    } catch (error) {
      console.error('Invalid/expired OTP');
      notification.error({
        message: 'Failed to reset password',
        description: 'Please check your OTP and try again.',
      });
    }
  };

  return (
    <div className='center-div'>
      <div className='admin-login-form'>
        <Form
          name="admin_login"
          onFinish={onFinish}
        >
          <h1>Admin Login</h1>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input placeholder="Email" autoComplete="email"/>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="link" onClick={() => setEmailModalVisible(true)}>
              Forgot password?
            </Button>
          </Form.Item>
        </Form>

        <Modal
          title="Forgot Password"
          open={emailModalVisible}
          onCancel={() => setEmailModalVisible(false)}
          footer={null}
        >
          <Form
            name="forgot_password"
            onFinish={onForgotPassword}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <Input placeholder="Email" autoComplete="email"/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Send OTP
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Enter OTP"
          open={otpModalVisible}
          onCancel={() => setOtpModalVisible(false)}
          footer={null}
        >
          <Form
            name="reset_password"
            onFinish={onResetPassword}
          >
            <Form.Item
              name="otp"
              rules={[{ required: true, message: 'Please input your OTP!' }]}
            >
              <Input placeholder="OTP"/>
            </Form.Item>
            <Form.Item
              name="new_password"
              rules={[{ required: true, message: 'Please input your new password!' }]}
            >
              <Input.Password placeholder="New Password"/>
            </Form.Item>
            <Form.Item
              name="confirm_password"
              dependencies={['new_password']}
              rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm New Password"/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default AdminLogin;
