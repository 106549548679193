import React, { useState, useEffect } from 'react';
import AdminLayout from './adminComponents';
import { Card, Table, Modal, Form, Input, Select, DatePicker, TimePicker, Button, Row, Col } from 'antd';
import { getAssistanceRequests, getAssistanceRequest, updateAssistanceRequest, getAllAgents, getTasksByService } from '../Routes';
import './styles/requests.css';
import dayjs from 'dayjs';

const { Option } = Select;

const Requests = () => {
  const [selectedButton, setSelectedButton] = useState('all');
  const [requests, setRequests] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({ agents: [] });
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);


  useEffect(() => {
    if (errorMessage) {
      Modal.error({
        title: 'An error occurred',
        content: errorMessage,
      });
      setErrorMessage(null);  // Clear the error message after displaying it
    }
  }, [errorMessage]);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const data = await getAssistanceRequests();
      setRequests(data);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
    fetchRequests();
  }, []);

  const fetchRequestDetails = async (requestId) => {
    try {
      const details = await getAssistanceRequest(requestId);
      setSelectedRequest(details);
      setIsModalVisible(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleButtonClick = (view) => {
    setSelectedButton(view);
  };

  const handleRowClick = (record) => {
    fetchRequestDetails(record.id);
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'User', dataIndex: 'user', key: 'user' },
    {   
      title: 'Agents',   
      dataIndex: 'agent',   
      key: 'agent',
      render: agents => Array.isArray(agents) ? agents.flat().map(agent => agent.full_name).join(', ') : ''
    },
    { title: 'Service', dataIndex: 'service', key: 'service' },
    { title: 'Start Date', dataIndex: 'start_date', key: 'start_date' },
    { title: 'End Date', dataIndex: 'end_date', key: 'end_date' },
    { title: 'Status', dataIndex: 'status', key: 'status' }
  ];

  const filteredRequests = requests.filter(request => {
    switch (selectedButton) {
      case 'new':
        return new Date(request.created_at) >= new Date(Date.now() -   24 *   60 *   60 *   1000);
      case 'active':
        return request.status === 'active';
      case 'completed':
        return request.status === 'completed';
      default:
        return true;
    }
  });

  const handleUpdateRequest = async () => {
    try {
       // Create a copy of selectedRequest to avoid mutating the state directly
       let updatedRequest = { ...selectedRequest };
   
       // Ensure the agents field is correctly structured as an array
       if (Array.isArray(updatedRequest.agents)) {
         // If the agents field is already correctly structured, no further action is needed
       } else if (updatedRequest.agent && Array.isArray(updatedRequest.agent)) {
         // If the agent field is an array, rename it to agents
         updatedRequest.agents = updatedRequest.agent;
         delete updatedRequest.agent; // Remove the old agent field
       } else {
         // Handle other cases as needed, e.g., if agent is a single object or undefined
         updatedRequest.agents = []; // Ensure agents is an array, even if empty
       }
   
       // Explicitly remove the agent field if it exists
       if (updatedRequest.hasOwnProperty('agent')) {
         delete updatedRequest.agent;
       }
   
       if (updatedRequest.payment_status === 'paid') {
         updatedRequest = { ...updatedRequest, status: 'active' };
       }
   
       // Log the updatedRequest state before sending
       console.log('UpdatedRequest state before sending:', updatedRequest);
   
       // Send the updatedRequest to the backend
       const response = await updateAssistanceRequest(updatedRequest.id, updatedRequest);
       console.log('Request updated successfully:', response);
       setIsEditMode(false);
       setIsModalVisible(false);
   
       // Update the requests state with the response from the backend
       setRequests(requests.map(req => req.id === updatedRequest.id ? response : req));
    } catch (error) {
       console.error('Failed to update request:', error);
    }
};
   
  return (
    <AdminLayout title="Requests">
      <div className='request-card'>
        <Card className='card' title="Active Requests">
          {requests.filter(request => request.status === 'active').length}
        </Card>
        <Card className='card' title="Completed Requests">
          {requests.filter(request => request.status === 'completed').length}
        </Card>
        <Card className='card' title="Pending Requests">
          {requests.filter(request => request.status === 'pending').length}
        </Card>
        <Card className='card' title="Total Requests">
          {requests.length}
        </Card>
      </div>
      <div className="request-for">
        <button className={selectedButton === 'all' ? 'selected' : ''} onClick={() => handleButtonClick('all')}>
            <span className="full-text">All Requests</span>
            <span className="short-text">All</span>
        </button>
        <button className={selectedButton === 'new' ? 'selected' : ''} onClick={() => handleButtonClick('new')}>
            <span className="full-text">New Requests</span>
            <span className="short-text">New</span>
        </button>
        <button className={selectedButton === 'active' ? 'selected' : ''} onClick={() => handleButtonClick('active')}>
            <span className="full-text">Active Requests</span>
            <span className="short-text">Active</span>
        </button>
        <button className={selectedButton === 'completed' ? 'selected' : ''} onClick={() => handleButtonClick('completed')}>
            <span className="full-text">Completed Requests</span>
            <span className="short-text">Completed</span>
        </button>
      </div>
      <Table columns={columns} dataSource={filteredRequests} onRow={(record) => ({ onClick: () => handleRowClick(record) })} />
      <RequestDetailsModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedRequest={selectedRequest}
        setSelectedRequest={setSelectedRequest}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        handleUpdateRequest={handleUpdateRequest}
      />
    </AdminLayout>
  );
};

const RequestDetailsModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedRequest,
  setSelectedRequest,
  isEditMode,
  setIsEditMode,
  handleUpdateRequest
 }) => {
  const hasTasks = selectedRequest && Array.isArray(selectedRequest.tasks);
  const [agents, setAgents] = useState([]);
  const [availableTasks, setAvailableTasks] = useState([]);
 
  useEffect(() => {
     if (isModalVisible && isEditMode) {
       const fetchAgents = async () => {
         try {
           const response = await getAllAgents();
           setAgents(response);
         } catch (error) {
           console.error('Failed to fetch agents:', error);
         }
       };
 
       const fetchTasks = async () => {
         try {
           const response = await getTasksByService(selectedRequest.service_id);
           setAvailableTasks(response);
         } catch (error) {
           console.error('Failed to fetch tasks:', error);
         }
       };
 
       fetchAgents();
       fetchTasks();
     }
  }, [isModalVisible, isEditMode, selectedRequest.service_id]);
 
 // Function to handle agent selection
const handleAgentSelection = (selectedAgentIds) => {
  // Convert the selected agent IDs to agent objects
  const selectedAgents = selectedAgentIds.map(id => agents.find(agent => agent.id === id));
  setSelectedRequest(prevState => ({ ...prevState, agent: selectedAgents }));
};


  return (
    <Modal
      title={selectedRequest ? selectedRequest.service : ''}
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        setIsEditMode(false);
      }}
      footer={isEditMode ? (
        <Button type="primary" onClick={handleUpdateRequest}>
          Update
        </Button>
      ) : (
        <Button type="primary" onClick={() => setIsEditMode(true)}>
          Edit
        </Button>
      )}
    >
      {selectedRequest && (
        <Form layout="vertical">
          <Form.Item label="Description">
            <Input value={selectedRequest.description} disabled={!isEditMode} />
          </Form.Item>
          <Form.Item label="Tasks">
            {hasTasks ? (
              <>
                <Select
                  placeholder="Select a task"
                  onChange={(value) => {
                    // Add the selected task to the request
                    setSelectedRequest(prevState => ({
                      ...prevState,
                      tasks: [...prevState.tasks, availableTasks.find(task => task.id === value)]
                    }));
                  }}
                  disabled={!isEditMode}
                >
                  {availableTasks.map((task) => (
                    <Option key={task.id} value={task.id}>{task.name}</Option>
                  ))}
                </Select>
                {selectedRequest.tasks.length > 0 && (
                  <Table
                    columns={[
                      { title: 'Title', dataIndex: 'task', key: 'task' },
                      { title: 'Price', dataIndex: 'amount', key: 'amount' },
                      {
                        title: 'Action',
                        key: 'action',
                        render: (text, record) => (
                          <Button disabled={!isEditMode} onClick={() => {
                            // Delete the task from the request
                            setSelectedRequest(prevState => ({
                              ...prevState,
                              tasks: prevState.tasks.filter(task => task.id !== record.key)
                            }));
                          }}>X</Button>
                        ),
                      },
                    ]}
                    dataSource={selectedRequest.tasks.map(task => ({ key: task.id, task: task.name, amount: task.amount }))}
                    pagination={false}
                  />
                )}
              </>
            ) : (
              <p>No tasks associated with this request.</p>
            )}
          </Form.Item>
          <Form.Item label="Payment Due">
            <Input 
              value={selectedRequest.request_amount} 
              onChange={e => setSelectedRequest({ ...selectedRequest, request_amount: e.target.value })}
              disabled={!isEditMode}
              prefix="$"
            />
          </Form.Item>
          <Form.Item label="Agents">
              {isEditMode ? (
                <Select
                  mode="multiple"
                  placeholder="Select Agents"
                  value={(selectedRequest?.agent || []).map(agent => agent.id)} // Use agent IDs as values
                  onChange={handleAgentSelection} // Use the updated function here
                >
                  {agents.map(agent => (
                    <Option key={agent.id} value={agent.id}>{agent.full_name}</Option> // Use agent IDs as values
                  ))}
                </Select>
              ) : (
                <div>
                  {(selectedRequest?.agent || []).map(agent => (
                    <p key={agent.id}>{agent.full_name}</p>
                  ))}
                </div>
              )}
            </Form.Item>
            <Form.Item label="Start Date and Time">
              <Row gutter={8}>
                <Col>
                  <DatePicker 
                    value={selectedRequest?.start_date ? dayjs(selectedRequest.start_date) : null} 
                    disabled={!isEditMode} 
                    onChange={(date, dateString) => {
                      setSelectedRequest({ ...selectedRequest, start_date: dateString });
                    }}
                  />
                </Col>
                <Col>
                  <TimePicker 
                    format='HH:mm'
                    value={selectedRequest?.start_time ? dayjs(`1970-01-01T${selectedRequest.start_time}:00`) : null} 
                    disabled={!isEditMode} 
                    onChange={(time, timeString) => {
                      setSelectedRequest({ ...selectedRequest, start_time: timeString });
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="End Date and Time">
              <Row gutter={8}>
                <Col>
                  <DatePicker 
                    value={selectedRequest?.end_date ? dayjs(selectedRequest.end_date) : null}
                    disabled={!isEditMode} 
                    onChange={(date, dateString) => {
                      setSelectedRequest({ ...selectedRequest, end_date: dateString });
                    }}
                  />
                </Col>
                <Col>
                  <TimePicker 
                    format='HH:mm'
                    value={selectedRequest?.end_time ? dayjs(`1970-01-01T${selectedRequest.end_time}:00`) : null} 
                    disabled={!isEditMode} 
                    onChange={(time, timeString) => {
                      setSelectedRequest({ ...selectedRequest, end_time: timeString });
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="Payment Status">
              <Select
                value={selectedRequest.payment_status}
                onChange={value => setSelectedRequest({ ...selectedRequest, payment_status: value })}
                disabled={!isEditMode}
              >
                <Option value="pending">Pending</Option>
                <Option value="paid">Paid</Option>
                <Option value="cancel">Cancel</Option>
              </Select>
            </Form.Item>
          </Form>
        )}
    </Modal>
    );
  };

export default Requests;
