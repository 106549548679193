import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminLayout from './adminComponents';
import { Button, Card, Table } from 'antd';
import { getAgentById, getRoleAssistanceRequests, getRoleScheduleRequests } from '../Routes';
import './styles/agentDetails.css';

const AgentDetails = () => {
  const { id } = useParams();
  const [agent, setAgent] = useState(null);
  const navigate = useNavigate();
  const [completedRequests, setCompletedRequests] = useState([]);
  const [ongoingRequests, setOngoingRequests] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [selectedButton, setSelectedButton] = useState('req');

  useEffect(() => {
    getAgentById(id)
      .then((data) => setAgent(data))
      .catch((error) => console.error('Error fetching agent:', error));
  
      getRoleAssistanceRequests(`agent`, id)
      .then((data) => {
        const completed = data.filter(request => request.status === 'completed');
        const ongoing = data.filter(request => request.status === 'active');
        setCompletedRequests(completed);
        setOngoingRequests(ongoing);
      })
      .catch((error) => console.error('Error fetching requests:', error));
  
      getRoleScheduleRequests('agent', id)
      .then((data) => setSchedules(data))
      .catch((error) => console.error('Error fetching schedules:', error));
  }, [id]);


  const handleBack = () => {
    navigate('/agents');
  };
  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };



  const ongoingRequestColumns = [
    {
      title: 'Request ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Service Name',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];
  
  const scheduleColumns = [
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Request ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Service Name',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    agent ? (
      <AdminLayout title={agent.full_name}>
        <div>
          <Button className='back-btn' type="primary" onClick={handleBack}>
            Back to Agents
          </Button>
          <div className="cards-container">
            <Card className='agent-request-card' title="Completed Requests">
              {completedRequests.length}
            </Card>
            <Card className='agent-request-card' title="Ongoing Requests">
              {ongoingRequests.length}
            </Card>
          </div>
          <div className="request-for">
          <button className={selectedButton === 'req' ? 'selected' : ''} onClick={() => handleButtonClick('req')}>
            <span className="full-text">Requests</span>
            <span className="short-text">Requests</span>
          </button>
          <button className={selectedButton === 'sch' ? 'selected' : ''} onClick={() => handleButtonClick('sch')}>
            <span className="full-text">Schedule</span>
            <span className="short-text">Schedule</span>
          </button>
        </div>
        {selectedButton === 'req' && (
          <Table
            dataSource={ongoingRequests}
            columns={ongoingRequestColumns}
            pagination={{ pageSize: 5 }}
          />
        )}
        {selectedButton === 'sch' && (
          <Table
            dataSource={schedules}
            columns={scheduleColumns}
            pagination={{ pageSize: 5 }}
          />
        )}
        </div>
      </AdminLayout>
    ) : (
      <div>Loading...</div>
    )
  );
};

export default AgentDetails;