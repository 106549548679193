import React, { useState, useEffect } from 'react';
import AdminLayout from './adminComponents';
import { Table } from 'antd';
import { getPayments } from '../Routes';  // Import the function to get all payments

const Payments = () => {
  const [allPayments, setAllPayments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPayments()
      .then((data) => setAllPayments(data))
      .catch((error) => console.error('Error fetching all payments:', error))
      .finally(() => setLoading(false));
  }, []);

  const columns = [
    { title: 'Payment ID', dataIndex: 'id', key: 'id' },
    { title: 'User', dataIndex: 'user', key: 'user' },
    { title: 'Request', dataIndex: 'agent', key: 'agent' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
  
  ];

  const paidPayments = allPayments.filter(payment => payment.status === 'paid');
  const pendingPayments = allPayments.filter(payment => payment.status === 'pending');

  return (
    <AdminLayout title="Payments">
      
      <h2>Paid Payments</h2>
      <Table columns={columns} dataSource={paidPayments} loading={loading} />

      <h2>Pending Payments</h2>
      <Table columns={columns} dataSource={pendingPayments} loading={loading} />

      <h2>All Payments</h2>
      <Table columns={columns} dataSource={allPayments} loading={loading} />

    </AdminLayout>
  );
};

export default Payments;