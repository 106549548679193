import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // Import the AuthProvider
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import AdminLogin from './admin/login';
import AdminDashboard from './admin/adminDashboard';
import Users from './admin/users';
import Agents from './admin/agents';
import Services from './admin/services';
import Schedules from './admin/schedule';
import Payments from './admin/payment';
import Admins from './admin/admins';
import Requests from './admin/requests';
import AgentDetails from './admin/agentDetails';
import Mctb from './admin/mctb';
import './App.css';

const App = () => {
  return (
    <Router>
      <AuthProvider> {/* Wrap your Routes with the AuthProvider */}
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          <Route path="/mctb" element={<Mctb />} />
          <Route path="/adminDashboard" element={<ProtectedRoute />}>
            <Route index element={<AdminDashboard />} />
          </Route>
          <Route path="/users" element={<ProtectedRoute />}>
            <Route index element={<Users />} />
          </Route>
          <Route path="/agents" element={<ProtectedRoute />}>
            <Route index element={<Agents />} />
          </Route>
          <Route path="/services" element={<ProtectedRoute />}>
            <Route index element={<Services />} />
          </Route>
          <Route path="/schedules" element={<ProtectedRoute />}>
            <Route index element={<Schedules />} />
          </Route>
          <Route path="/payments" element={<ProtectedRoute />}>
            <Route index element={<Payments />} />
          </Route>
          <Route path="/admins" element={<ProtectedRoute />}>
            <Route index element={<Admins />} />
          </Route>
          <Route path="/requests" element={<ProtectedRoute />}>
            <Route index element={<Requests />} />
          </Route>
          <Route path='/agent-details/:id' element={<ProtectedRoute />}>
            <Route index element={<AgentDetails />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
