import React,  { useState, useEffect } from 'react';
import AdminLayout from './adminComponents';
import { Table, Card, Row, Col } from 'antd';
import { getAllUsers, countAllUsers, getTopUsers } from '../Routes';

const columns = [
    
    {
      title: 'User ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Total Requests',
      dataIndex: 'totalRequests',
      key: 'totalRequests',
    }
    
  ];

const Users = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [topUsers, setTopUsers] = useState([]);
    
  
    useEffect(() => {
      // fetch all users and set them to the state
      getAllUsers()
        .then((data) => setAllUsers(data))
        .catch((error) => console.error('Error fetching all users:', error));
  
        // Fetch admin users count and set it to the state
        countAllUsers()
          .then((data) => setTotalUsers(data.count))
          .catch((error) => console.error('Error fetching admin users count:', error));

        // Fetch top users and set them to the state
        getTopUsers()
        .then((data) => {
            const usersWithRequestCounts = data.map(([user, count]) => ({
                ...user,
                totalRequests: count,
            }));
            setTopUsers(usersWithRequestCounts);
        })
        .catch((error) => console.error('Error fetching top users:', error));
      
    }, []);

  return (
    <AdminLayout title="Users">
       <Row gutter={16}>
        <Col span={8}>
          <Card title="Users" style={{ marginBottom: 16 }}>
            <h1>{totalUsers}</h1>
          </Card>
        </Col>
        <Col span={16}>
          <Card title="Top 5 Users" style={{ marginBottom: 16 }}>
            <Table dataSource={topUsers} columns={columns} pagination={false} />
          </Card>
        </Col>
      </Row>

      <Card title="All Users">
        <Table dataSource={allUsers} columns={columns} pagination={{ pageSize: 10 }} />
      </Card>
    </AdminLayout>
  );
};

export default Users;
